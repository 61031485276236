<template>
  <div>
    <b-link id="show-btn click" @click="$bvModal.show('uploadPrices')">
      <b-button variant="primary" class="mr-1 text-nowrap">
        {{ 'Actualizar precios provincias' }}
      </b-button>
    </b-link>
    <b-modal id="uploadPrices" :hide-footer="true" size="lg">
      <validation-observer ref="uploadPrices">
        <b-card-title title-tag="h1" class="font-weight-bolder mb-1 text-center">
          Actualizar precios provincias mediante CSV
        </b-card-title>
        <div class="asignar-trabajos-modal">
          <b-row class="justify-content-center middle" align-v="center">
            <b-col class="text-center" lg="10">
              <span>Debe utilizar la plantilla proporcionada o no se subirán los precios correctamente</span>
            </b-col>
          </b-row>

          <b-form class="mt-2" @submit.prevent="handleSubmitUploadPrices" @keydown.enter="handleSubmitUploadPrices">
            <b-container class="pl-3 pr-3">
              <b-row class="justify-content-center middle" align-v="center">
                <b-col lg="8">
                  <!--TODO: como hace requerido un csv?-->
                  <b-form-group :label="'CSV'" label-for="csv">
                    <ImageDropzone ref="excel" max-files="1" />
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row class="text-center mb-3">
                <b-col>
                  <b-button type="submit" variant="primary" class="mt-1 mr-1 text-uppercase">
                    {{ 'Actualizar' }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
          <b-card-footer class="d-block text-center">
            <a href="/csv/PlantillaPrecioProvincias.csv" download>
              Descargar plantilla CSV
            </a>
          </b-card-footer>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import {
  BButton,
  BCardTitle,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BLink,
  BRow,
} from 'bootstrap-vue'

export default {
  name: 'UploadCSV',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    ValidationObserver,
    ImageDropzone,
    BLink,
    BCardTitle,
    BContainer,
  },
  data() {
    return {
      excel: '',
      tipoUpload: '',
    }
  },
  methods: {
    ...mapActions({
      uploadPrices: 'provinces/uploadPrices',
    }),
    handleSubmitUploadPrices() {
      this.$refs.uploadPrices.validate().then(success => {
        if (success) {
          const formData = this.createFormDataUpload()
          if (formData) {
            this.uploadPrices({ prices: formData })
          }
        }
      })
    },
    createFormDataUpload() {
      return this.$refs.excel.getFormData('excel')
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
