<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div
            class="d-flex justify-content-between align-items-center px-1 pt-2 pb-1"
          >
            <div class="col-3">
              <h3 class="m-0">{{ "Precios de envío por provincia" }}</h3>
            </div>
            <div class="d-flex align-items-center">
              <UploadCSV/>
            </div>
          </div>
            <hr/>
            <div class="d-flex justify-content-end align-items-center px-1 pt-1 pb-2">
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('Busqueda')"
                type="text"
                class="d-inline-block w-auto"
              />
              <b-button class="ml-1" @click="handleSearch" variant="primary">
                {{ $t("Buscar") }}
              </b-button>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="columns"
            :no-local-sorting="true"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template #cell(name)="data">
              <p class="m-0">{{ data.item.name }}</p>
            </template>
            <template #cell(price)="data">
              <p class="m-0">{{ data.item.price }}</p>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'editProvince', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-link>
              </span>
            </template>
          </b-table>
          <div
              v-if="totalItems > pageLength"
            class="d-flex align-items-center justify-content-between mb-1 px-2"
          >
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BTable,
  BCol,
  BRow,
  BLink,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import UploadCSV from './Upload'

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BFormGroup,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    UploadCSV,
  },
  data() {
    return {
      visibleFilter: false,
      log: [],
      sortBy: "",
      sortDesc: "",
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ["10", "15", "25"],
      columns: [
        {
          label: "Nombre",
          key: "name",
          sortable: true,
        },
        {
          label: "Precio 1000",
          key: "price_1000",
          sortable: true,
        },
        {
          label: "Precio 2000",
          key: "price_2000",
          sortable: true,
        },
        {
          label: "Precio 3000",
          key: "price_3000",
          sortable: true,
        },
        {
          label: "Precio 4000",
          key: "price_4000",
          sortable: true,
        },
        {
          label: "Precio 5000",
          key: "price_5000",
          sortable: true,
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      items: "provinces/getItems",
      totalItems: "provinces/getTotalItems",
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    sortBy() {
      this.currentPage = 1;
      this.chargeData();
    },
    sortDesc() {
      this.currentPage = 1;
      this.chargeData();
    },
    orderby() {
      this.currentPage = 1;
      this.chargeData();
    },
    status() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  created() {
    this.chargeData();
  },
  methods: {
    ...mapActions({
      list: "provinces/getListProvinces",
    }),
    chargeData() {
      let orderBy = "";

      if (this.sortBy) {
        const type = this.sortDesc ? "desc" : "asc";
        orderBy = {
          column: this.sortBy,
          type,
        };
      }
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        orderBy,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
